import React from "react"
import {graphql, Link} from "gatsby"
import Helmet from "react-helmet"
import MainLayout from "Layout/layout"
import Img from "gatsby-image"
import FlexibleContent from "Layout/FlexibleContent/FlexibleContent"
import SliderBasic from "Accessories/Slider/SliderBasic/SliderBasic"
import EventListing from "Layout/Events/EventListing/EventListing"
import config from "../../../data/SiteConfig"
import "./project.scss"

const ProjectTemplate = ({
  data,
  location
}) => {

  const {page, events} = data

  const projectTag = page.categories_names.filter(itemAttribute => itemAttribute.taxonomy === "event_match").length ?
    page.categories_names.filter(itemAttribute => itemAttribute.taxonomy === "event_match")[0] &&
      page.categories_names.filter(itemAttribute => itemAttribute.taxonomy === "event_match")[0].name.toString().toLowerCase() : ""

    const similarEvents = events.edges.filter(event => {
      const eventTag = event.node.categories_names.filter(item => item.taxonomy === "event_tags").length ? event.node.categories_names.filter(item => item.taxonomy === "event_tags").map(item => item.name).toString().toLowerCase() : ""
      return eventTag !== "" && eventTag === projectTag
    })

  if (similarEvents.length > 6) similarEvents.length = 6

  const swiperSetting = {
    slidesPerView: 1,
    grabCursor: false,
    lazy: true,
    breakpointsInverse: true,
    breakpoints: {
      1024: {
        allowTouchMove: false,
      }
    }
  }

  return (
    <MainLayout location={location}>
      <Helmet>
        <title>{`${page.title} | ${config.siteTitle}`}</title>
      </Helmet>
      <div className="section-project-content section pt-2 pt-md-5 container-fluid">
        <div className="container">
          <div className="row project-details-row">
            <div className="project-details col-12 col-lg-3 order-2 order-lg-1 mt-2 mt-lg-8">
              <div className="project-research-track mb-1">
                <h2 className="bracket-text text-sm">Research Track</h2>
                <span className="text-sm d-block">
                  {page.categories_names.filter(item => item.taxonomy === "research_track")[0] && page.categories_names.filter(item => item.taxonomy === "research_track")[0].name === "Global Education" ? "Global Education" : page.acf.project.project_research_track && page.acf.project.project_research_track.post_title}
                </span>
              </div>
              {page.acf.project.project_partners && page.acf.project.project_partners.length && (
                <div className="project-partners mb-1">
                  <h2 className="bracket-text text-sm">Partners</h2>
                  {
                    page.acf.project.project_partners.map(projectPartner => {
                      return (
                        <span
                          className="d-block text-sm"
                          key={projectPartner.project_partner_name}
                        >
                          {
                            projectPartner.project_partner_link ?
                            (<a href={projectPartner.project_partner_link} rel="noopener noreferrer" target="_blank">{projectPartner.project_partner_name}</a>) :
                            (projectPartner.project_partner_name)
                          }
                        </span>
                      )
                    })
                  }
                </div>
              )}
              {page.acf.project.project_conveners && page.acf.project.project_conveners.length && (
                <div className="project-conveners mb-1">
                  <h2 className="bracket-text text-sm">Conveners</h2>
                  <div dangerouslySetInnerHTML={{ __html: page.acf.project.project_conveners }} className="text-sm" />
                </div>
              )}
              {page.acf.project.project_participants && page.acf.project.project_participants.length && (
                <div className="project-participants mb-1">
                  <h2 className="bracket-text text-sm">Participants</h2>
                  <div dangerouslySetInnerHTML={{ __html: page.acf.project.project_participants }} className="text-sm" />
                </div>
              )}
              {page.acf.project.project_links && page.acf.project.project_links.length && (
                <div className="project-links mb-1">
                  <h2 className="bracket-text text-sm">Links</h2>
                  {
                    page.acf.project.project_links.map(projectLink => {
                      return (
                        <a
                          href={projectLink.link_target}
                          target="_blank"
                          rel="noopener noreferrer"
                          title={projectLink.link_text}
                          key={projectLink.link_text}
                          className="d-block text-sm"
                        >
                          {projectLink.link_text}
                        </a>
                      )
                    })
                  }
                </div>
              )}
              {page.categories_names && page.categories_names.filter(item => item.taxonomy === "project_tags").length ? (
                <div className="project-tags">
                  <h2 className="bracket-text text-sm">Tags</h2>
                  {
                    page.categories_names
                      .filter(item => item.taxonomy === "project_tags")
                      .map(item => {
                        return (
                          <Link
                            to={`/index-list/#${item.name.toLowerCase()}`}
                            className="text-dot d-inline-block tag text-sm"
                            key={item.name.toLowerCase()}
                          >
                            {item.name}
                          </Link>
                        )
                      })
                  }
                </div>
              ) : null}
            </div>
            <div className="project-content col-12 col-lg-7 order-1 order-lg-2">
              <h2 className="bracket-text text-sm">Project</h2>
              <br />
              <h1 className="mb-1">{page.title}</h1>
              {
                page.acf.project.project_intro && <div dangerouslySetInnerHTML={{ __html: page.acf.project.project_intro }} className="mb-1 project-intro-container text-lg" />
              }
              {
                page.acf.project.project_details && <div dangerouslySetInnerHTML={{ __html: page.acf.project.project_details }} className="mb-2 description-container" />
              }
              {
                page.acf.project.project_images && page.acf.project.project_images.length && (
                  <SliderBasic settings={swiperSetting}>
                    {page.acf.project.project_images.map(
                      (projectImage) => projectImage.image && projectImage.image.localFile && (
                        <div
                          className="project-slide"
                          key={projectImage.image.localFile.childImageSharp.fluid.originalName}
                        >
                          {
                            projectImage.image.localFile.childImageSharp.fluid && <Img fluid={projectImage.image.localFile.childImageSharp.fluid} />
                          }
                          {
                            projectImage.caption && <span className="image-caption d-inline-block text-sm">{projectImage.caption}</span>
                          }
                        </div>
                      )
                    )}
                  </SliderBasic>
                )
              }
              {page.acf.content_projects && page.acf.content_projects.length && (
                <FlexibleContent content={page.acf.content_projects} location={location} pageName="ProjectTemplate" />
              )}
            </div>
          </div>
        </div>
      </div>
      {
        projectTag && (
          <div className="container-fluid bg-white mt-2">
            <div className="container">
              <EventListing events={similarEvents} classes="row" />
            </div>
          </div>
        )
      }
    </MainLayout>
  )
}

export const pageQuery = graphql`
  query projectSingleQuery($id: String!) {
    page: wordpressWpProjects(id: { eq: $id }) {
      id
      slug
      title
      categories_names {
        name
        taxonomy
        slug
      }
      acf {
        project {
          project_partners {
            project_partner_name
            project_partner_link
          }
          project_conveners
          project_participants
          project_links {
            link_text
            link_target
          }
          project_research_track {
            post_title
          }
          project_intro
          project_details
          project_images {
            caption
            image {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1646) {
                    ...GatsbyImageSharpFluid_noBase64
                    originalName
                  }
                }
              }
            }
          }
        }
        content_projects {
          __typename
          ...BasicSliderSectionProjectFragment
          ...TextSectionProjectFragment
        }
      }
    }
    events: allWordpressWpEventPosts(
      sort: {
        fields: [date], order:DESC
      }
    ) {
      edges {
        node {
          id
          slug
          title
          excerpt
          categories_names {
            name
            taxonomy
          }
          acf {
            event {
              event_venue
              event_date_info {
                event_date_info_end_date
                event_date_info_end_time
                event_date_info_start_date
                event_date_info_start_time
              }
            }
          }
        }
      }
    }
  }
`

export default ProjectTemplate
