import React, {useState} from "react"
import Swiper from "react-id-swiper"
import {Navigation} from "swiper/dist/js/swiper.esm"
import Button from "Accessories/Button/Button"
import PropTypes from "prop-types"
import "./SliderBasic.scss"

const SliderBasic = ({
  settings,
  children,
  classes
}) => {

  const [currentSlideCount, setCurrentSlideCount] = useState(1)
  const [swiper, updateSwiper] = useState(null)
  const totalSlides = children.length

  const goNext = () => {
    if (swiper !== null) {
      swiper.slideNext()
      setCurrentSlideCount(swiper.activeIndex + 1)
    }
  }

  const goPrev = () => {
    if (swiper !== null) {
      swiper.slidePrev()
      setCurrentSlideCount(swiper.activeIndex + 1)
    }
  }

  return (
    <div className="section section-swiper">
      <Swiper
        getSwiper={updateSwiper}
        modules={[Navigation]}
        {...settings}
        containerClass={classes}
      >
        { children }
      </Swiper>
      {
        totalSlides > 1 && (
          <div className="d-flex justify-content-between justify-content-md-center align-items-center swiper-info-wrapper">
            <span className="item-count text-md d-inline-block">{`${currentSlideCount} — ${totalSlides}`}</span>
            <div className="swiper-controls-wrapper">
              <div className="swiper-controls">
                <Button
                  className={`swiper-prev ${currentSlideCount === 1 ? "is-deactivated" : ""}`}
                  text="Prev"
                  onClick={goPrev}
                />
                <Button
                  className={`swiper-next ${currentSlideCount === totalSlides ? "is-deactivated" : ""}`}
                  text="Next"
                  onClick={goNext}
                />
              </div>
            </div>
          </div>
        )
      }
    </div>
  )
}

SliderBasic.propTypes = {
  settings: PropTypes.instanceOf(Object), // eslint-disable-line react/forbid-prop-types,
}

SliderBasic.defaultProps = {
  settings: {
    grabCursor: false,
    slidesPerView: "auto",
    loop: false,
  }
}

export default SliderBasic
