import React from "react"
import { Link } from "gatsby"
import cn from "classnames"
import "./EventListing.scss"

const EventListing = ({ events, classes }) => {
  return (
    <div className={cn("event-listing-section", classes)}>
      {events.length ? (
        events.map(event => {
          const eventCategories = event.node.categories_names.filter(item => item.taxonomy === "types")
          const eventLocation = event.node.acf.event.event_venue && event.node.acf.event.event_venue
          const eventYear = event.node.categories_names.filter(item => item.taxonomy === "years")

          return (
            <Link
              to={`/events/${event.node.slug}`}
              key={event.node.slug}
              className="event-wrapper col-md-6 col-lg-4 text-sm py-2 py-md-1"
              data-location={eventLocation}
              data-year={eventYear.length && eventYear[0].name}
              data-type={eventCategories.length && eventCategories[0].name}
            >
              <div className="event-header">
                <div className="event-title">
                  <span className="bracket-text d-block">
                    {eventCategories.length && eventCategories[0].name}
                  </span>
                  <span>{event.node.title}</span>
                  {event.node.acf.event.event_subtitle && <span>{` • ${event.node.acf.event.event_subtitle}`}</span>}
                </div>
                <div className="event-info">
                  {event.node.acf.event.speaker_name && <h3 className="d-block">{event.node.acf.event.speaker_name}</h3>}
                  {
                    event.node.acf.event.event_date_info && (
                      <span>
                        {event.node.acf.event.event_date_info.event_date_info_start_date && event.node.acf.event.event_date_info.event_date_info_start_date}
                        {event.node.acf.event.event_date_info.event_date_info_start_time && ` ${event.node.acf.event.event_date_info.event_date_info_start_time}`}
                        {event.node.acf.event.event_date_info.event_date_info_end_date && ` – ${event.node.acf.event.event_date_info.event_date_info_end_date}`}
                        {event.node.acf.event.event_date_info.event_date_info_end_time && ` ${event.node.acf.event.event_date_info.event_date_info_end_time}`}
                      </span>
                    )
                  }
                  {eventLocation && <span className="d-block">{eventLocation}</span>}
                </div>
              </div>
              {event.node.excerpt && event.node.excerpt.length && <div className="event-body mt-1" dangerouslySetInnerHTML={{ __html: event.node.excerpt }} />}
            </Link>
          )
        })
      ) : (
        <div className="no-results-container">
          <p>There are no results for your search.</p>
        </div>
      )}
    </div>
  )
}

export default EventListing
